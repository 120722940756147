.login-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.extend-bi-logo {
  width: 80%;
}

.login-version {
  color: #595959;
  margin-bottom: 50px;
  text-align: right;
  font-style: italic;
}

.login-wrapper {
  width: 300px;
}

.login-form-button {
  width: 100%;
}
