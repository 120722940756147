.fileListShow {
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.85);
  background-color: #fafafa;
  cursor: pointer;
  padding: 0 5px;
}
.helpIcon {
 margin-left: 10px;
 cursor: pointer; 
}

/* hidden but present, It is needed when antd validation fails to scroll */
.formItemHiddenStyle {
  width: 0;
  padding: 0;
  margin: 0;
  border: none;
}