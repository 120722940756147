.granules-summary-container {
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.granules-summary-block {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.with-borders {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
