.sidebar-logo {
  height: 32px;
  margin: 16px;
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.user-dropdown-heading {
  min-height: 50px;
  width: 220px;
  text-align: center;
  padding: 20px;
}

.user-dropdown-item {
  text-align: center;
}

.user-dropdown-text {
  margin: 0 10px;
}

.ant-menu-submenu-disabled,
.ant-menu-item-disabled {
  display: none !important;
}